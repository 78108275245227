.button-main {
    max-height: 40px;
    margin: 0 10px 0 5px;
}
.score-history-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    height: 30px;
    min-width: 30px;
    margin: 0 10px; 
    border: 0;
}