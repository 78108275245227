.score-history-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.changeScoreInput {
  @media only screen and (max-width: 600px) {
    input {
      width: 50px;
    }

    //    position: absolute;
  }
}
