a {
  cursor: pointer;
}

.main-block {
  border: black 1px solid;
  border-radius: 5px;
  box-shadow: 10px 5px 5px grey;
  margin-top: 100px;
  min-height: 400px;

  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle,
    rgb(174, 200, 238) 0%,
    rgba(148, 187, 233, 0.2) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;

  .add-players-container {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  @media only screen and (max-width: 600px) {
    margin-top: 20px;
  }
}

.outer-container {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(172, 171, 184, 0.8) 0%,
    rgba(215, 211, 228, 0.433) 35%,
    rgba(189, 196, 198, 0.8) 100%
  );
  height: 100vh;
  .nav-bar-custom {
    background: rgba(174, 200, 238, 0.40) 99%;
    box-shadow: 0 4px 4px grey;
  }
}




